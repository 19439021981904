import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormField, TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import { Button } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';
import TravelFormInput from '@/features/travel-form/travel-form-expanded/travel-form-input/travel-form-input';
import useTravelForm from '@/features/travel-form/travel-form-expanded/use-travel-form';

import styles from './travel-form-expanded.module.scss';

const bem = bemModule(styles);

export type TravelFormOverlayName = keyof TravelFormDataV1Attributes['filters'];

export type TravelFormExpandedProps = {
    activeFilters: TravelFormDataV1Attributes['activeFilters'];
    activity: TravelFormField | null;
    airports: TravelFormField | null;
    cta: {
        dependsOn: TravelFormFilterName[];
        text: string;
    };
    date: TravelFormField | null;
    destination: TravelFormField | null;
    duration: TravelFormField | null;
    isLoading: boolean;
    noBackground?: boolean;
    noPadding?: boolean;
    onInputClick: (overlayName: TravelFormOverlayName) => void;
    onSubmit: () => void;
    onTravelFormLoaded?: () => void;
    overlayType: TravelFormOverlayName | null;
    roomAllocation: TravelFormField | null;
};

export default function TravelFormExpanded({
    activeFilters,
    activity,
    airports,
    cta,
    date,
    destination,
    duration,
    isLoading,
    noBackground = false,
    noPadding = false,
    onInputClick,
    onSubmit,
    onTravelFormLoaded = () => {},
    overlayType,
    roomAllocation,
}: TravelFormExpandedProps) {
    const { expandedWrapperRef, getInputError, onInputClickHandler, onSubmitHandler } = useTravelForm({
        activeFilters,
        onInputClick,
        onSubmit,
        onTravelFormLoaded,
    });

    return (
        <div
            className={bem(styles.expandedWrapper, { noBackground, noPadding })}
            data-qa-id={'qa-travel-form-expanded'}
            ref={expandedWrapperRef}
        >
            <div className={styles.expanded}>
                <div className={styles.inputWrapper}>
                    {activity?.placeholderText && (
                        <TravelFormInput
                            error={getInputError('activity', activity.error)}
                            iconUrl={activity.iconUrl}
                            isLoading={isLoading && overlayType === 'activity'}
                            onClick={() => onInputClickHandler('activity')}
                            qaId={'qa-travel-form-input-activity'}
                            value={activity.placeholderText}
                        />
                    )}
                    {destination?.placeholderText && (
                        <TravelFormInput
                            error={getInputError('destination', destination.error)}
                            iconUrl={destination.iconUrl}
                            isLoading={isLoading && overlayType === 'destination'}
                            onClick={() => onInputClickHandler('destination', destination.dependsOn)}
                            qaId={'qa-travel-form-input-destination'}
                            value={destination.placeholderText}
                        />
                    )}
                    {airports?.placeholderText && (
                        <TravelFormInput
                            error={getInputError('airports', airports.error)}
                            iconUrl={airports.iconUrl}
                            isLoading={isLoading && overlayType === 'airports'}
                            onClick={() => onInputClickHandler('airports')}
                            qaId={'qa-travel-form-input-airports'}
                            value={airports.placeholderText}
                        />
                    )}
                    {date?.placeholderText && (
                        <div
                            className={bem(styles.expandedDateConfigContainer, {
                                hasDuration: !!duration?.placeholderText,
                            })}
                        >
                            <TravelFormInput
                                error={getInputError('date', date.error)}
                                iconUrl={date.iconUrl}
                                isLoading={isLoading && overlayType === 'date'}
                                onClick={() => onInputClickHandler('date')}
                                qaId={'qa-travel-form-input-date'}
                                value={date.placeholderText}
                            />
                            {duration?.placeholderText && (
                                <TravelFormInput
                                    error={getInputError('duration', duration.error)}
                                    isLoading={isLoading && overlayType === 'duration'}
                                    onClick={() => onInputClickHandler('duration')}
                                    qaId={'qa-travel-form-input-duration'}
                                    value={duration.placeholderText}
                                />
                            )}
                        </div>
                    )}
                    {roomAllocation?.placeholderText && (
                        <TravelFormInput
                            error={getInputError('roomAllocation', roomAllocation.error)}
                            iconUrl={roomAllocation.iconUrl}
                            isLoading={isLoading && overlayType === 'roomAllocation'}
                            onClick={() => onInputClickHandler('roomAllocation')}
                            qaId={'qa-travel-form-input-roomAllocation'}
                            value={roomAllocation.placeholderText}
                        />
                    )}
                </div>
                <div className={styles.ctaWrapper}>
                    <Button
                        className={styles.cta}
                        fontSize={18}
                        height={48}
                        onClick={() => onSubmitHandler(cta.dependsOn)}
                        qaId={'qa-travel-form-close-modal'}
                        title={cta.text}
                    />
                </div>
            </div>
        </div>
    );
}
